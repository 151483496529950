var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Add Margin Fees","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Product Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Product Type","label-for":"service_type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"service_type","state":_vm.serviceTypeSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.serviceTypeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.serviceTypeSelect),callback:function ($$v) {_vm.serviceTypeSelect=$$v},expression:"serviceTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type","label-for":"type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"active","state":_vm.typeSelect === null ? false : true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.typeSelect),callback:function ($$v) {_vm.typeSelect=$$v},expression:"typeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"From","label-for":"from"}},[_c('validation-provider',{attrs:{"name":"to","rules":"required|regex:^[0]*[1-9][0-9]*$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"From","min":"1","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.data.from),callback:function ($$v) {_vm.$set(_vm.data, "from", $$v)},expression:"data.from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"To","label-for":"to"}},[_c('validation-provider',{attrs:{"name":"to","rules":"required|regex:^[0]*[1-9][0-9]*$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"to","min":"1","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.data.to),callback:function ($$v) {_vm.$set(_vm.data, "to", $$v)},expression:"data.to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Rate","label-for":"rate"}},[_c('validation-provider',{attrs:{"name":"rate","rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rate","min":"1","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.data.rate),callback:function ($$v) {_vm.$set(_vm.data, "rate", $$v)},expression:"data.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Is Portal ?","label-for":"is_portal"}},[_c('validation-provider',{attrs:{"name":"is_portal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.data.is_portal),callback:function ($$v) {_vm.$set(_vm.data, "is_portal", $$v)},expression:"data.is_portal"}},[(_vm.data.is_portal)?_c('p',[_vm._v(" Portal Price Rate ")]):_c('p',[_vm._v(" In Store Price Rate")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Is Active ?","label-for":"active"}},[_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.data.active),callback:function ($$v) {_vm.$set(_vm.data, "active", $$v)},expression:"data.active"}},[(_vm.data.active)?_c('p',[_vm._v(" Price Rate is Active To Used ")]):_c('p',[_vm._v(" Price Rate is not Active")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Alert ")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values){return _c('ul',{key:values},_vm._l((values),function(value){return _c('li',{key:value},[_vm._v(_vm._s(value))])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }